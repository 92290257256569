import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { StaticImage } from 'gatsby-plugin-image';
import { IoMdClose } from 'react-icons/io';

export const SmallMap = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const handleOpen = () => {
    setPopupOpen(true);
  };

  const handleClose = () => {
    setPopupOpen(false);
  };

  return (
    <>
      <div className="cursor-pointer" onClick={handleOpen}>
        <StaticImage
          height={800}
          src="../../images/other/vicenza_anteprima_mappa.png"
          quality={100}
          alt="mappa"
        />
      </div>
      <Modal
        show={isPopupOpen}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
      >
        <Modal.Header className="justify-content-end">
          <div className="cursor-pointer me-3">
            <a
              href="/files/Vicenza_mappa-01.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../../images/icons/icona download.png"
                quality={100}
                height={25}
                width={25}
                alt="mappa"
              />
            </a>
          </div>
          <IoMdClose
            color="#324189"
            size={36}
            className="cursor-pointer"
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <StaticImage
            src="../../images/other/vicenza_mappa.png"
            quality={100}
            alt="mappa"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
