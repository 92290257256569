import React from 'react';
import { Layout } from '../components/layout';
import { Hero } from '@/components/layout/header/hero';
import { StaticImage } from 'gatsby-plugin-image';
import { Divider } from '@/components/ui/Divider';
import { Link } from 'gatsby';
import { SmallMap } from '@/components/maps/SmallMap';

export default function ParcheggiDiIntercambioECamper() {
  const pageTitle = 'Parcheggi di intercambio e Camper';

  const Banner = () => (
    <StaticImage
      src="../images/banners/parcheggi-di-intercambio-e-camper.png"
      alt={pageTitle}
      quality={100}
    />
  );

  const Left = () => <SmallMap />;

  const Right = () => (
    <div>
      <div className="mb-3">
        <div>
          <h2 className="h3 text-primary font-rubik">
            Indicazione planimetrica parcheggi di intercambio
          </h2>
          <div className="mt-4 mb-5">
            <div className="mb-4">
              <h3 className="text-uppercase h4 font-rubik text-primary mb-1">
                Parcheggio Cricoli
              </h3>
              <p className="text-secondary">542 posti auto</p>
            </div>
            <div>
              <h3 className="text-uppercase h4 font-rubik text-primary mb-1">
                Parcheggio Stadio
              </h3>
              <p className="text-secondary">605 posti auto</p>
            </div>
          </div>
        </div>
        <div>
          <Link to={'/modulistica'}>
            <button className="btn btn-primary mb-3">
              Consulta i regolamenti di sosta
            </button>
          </Link>
        </div>
      </div>
    </div>
  );

  const Center = () => (
    <div>
      <div>
        <h2 className="text-center h2 text-primary font-rubik">
          AREE DI SOSTA CAMPER PARK CRICOLI E PARK STADIO
        </h2>
        <p
          className="mx-auto mt-5 mb-5 text-secondary"
          style={{ maxWidth: '960px' }}
        >
          Presso i parcheggi Stadio e Cricoli, è attivo un servizio di sosta
          camper. Il servizio mette a disposizione dei proprietari dei camper
          degli impianti per lo scarico delle acque nere e rubinetti con acqua
          potabile per il rifornimento dei serbatoi d’acqua dei camper, e
          inoltre colonnine elettriche per la ricarica. Dai parcheggi di
          interscambio sono attive due servizi bus-navetta, denominate
          “Centrobus Cricoli” e “Centrobus Stadio”, che fanno parte del servizio
          di trasporto pubblico locale. Negli orari di attivazione del Trasporto
          Pubblico Locale (dalle ore 6:00 alle ore 22:00), l'accesso ai
          parcheggi di interscambio Stadio e Cricoli avviene con emissione del
          biglietto di andata e ritorno per il servizio di Trasporto Pubblico
          Locale di € 2,40 (linea 10 per il parcheggio Stadio e linea 30 per il
          parcheggio Cricoli), mentre la sosta è gratuita. Per quanto riguarda
          il servizio camper presso i parcheggi Stadio e Cricoli, lo stesso è
          fruibile compreso nel biglietto “Centrobus A/R Camper”,
          nell’abbonamento solo sosta camper ed in abbonamento con utilizzi a
          scalare (carnet), come riportato in "TARIFFE PARCHEGGI INTERSCAMBIO".
        </p>
      </div>
      <div className="text-center">
        <h2 className="font-rubik text-primary h3 mb-5">
          Consulta le nostre tariffe dei parcheggi di interscambio
        </h2>
        <div className="mb-5">
          <a
            href="/files/tariffe-e-orari/ALLEGATO_ TARIFFE PARCHEGGI INTERSCAMBIO.pdf"
            target="_blank"
          >
            <button className="btn btn-primary mb-3">
              Tariffe parcheggi interscambio
            </button>
          </a>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-center mb-5">
        <div className="d-flex align-self-center">
          <p className="text-secondary">
            Per maggiori info su CENTROBUS consulta il sito di
          </p>
        </div>
        <div className="d-flex align-self-center">
          <a href="https://www.svt.vi.it/" target="_blank">
            <StaticImage
              src="../images/logos/logo-svt.png"
              quality={100}
              alt="svt"
            />
          </a>
        </div>
      </div>
    </div>
  );

  return (
    <Layout title={pageTitle}>
      <Hero title={pageTitle} banner={<Banner />} />
      <div className="container mt-5 mb-5">
        <div className="row gx-5">
          <div className="col-12 col-lg-5">
            <Left />
          </div>
          <div className="col-12 col-lg-7 mt-3">
            <Right />
          </div>
        </div>
        <Divider size={5} />
        <Center />
      </div>
    </Layout>
  );
}
